
import { defineComponent } from "vue";
import axios from "axios";
import { formatPassword } from "@/utils/password";
import { ElMessage } from "element-plus";
import { store } from "@/utils/store";

export default defineComponent({
  name: "App",
  props: {
    visible: Boolean,
    handleClose: Function,
  },
  data() {
    return {
      store,
      formData: {
        email: "",
        password: "",
        verifyCode: "",
        isSent: false,
        loading: false,
      },
      emailError: "",
      countdown: 0,
    };
  },
  computed: {
    isValidEmail() {
      const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
      if (this.formData.email && !this.formData.email.match(emailPattern)) {
        return "Invalid email address";
      } else {
        return "";
      }
    },
  },
  methods: {
    handleSubmit() {
      if (!this.formData.email) {
        ElMessage({
          message: "please enter email",
          type: "warning",
        });
        return;
      }
      if (!this.formData.password) {
        ElMessage({
          message: "please enter password",
          type: "warning",
        });
        return;
      }
      if (!this.formData.isSent) {
        ElMessage({
          message: "please send code",
          type: "warning",
        });
        return;
      }
      if (!this.formData.verifyCode) {
        ElMessage({
          message: "please enter code",
          type: "warning",
        });
        return;
      }
      var config = {
        method: "post",
        url: `/api/ai-health//master-station/member-register/firstStep?email=${
          this.formData.email
        }&emailCode=${this.formData.verifyCode}&password=${formatPassword(
          this.formData.password
        )}`,
      };

      axios(config)
        .then((response) => {
          if (response.data.code === 200) {
            ElMessage({
              message: "Register success.",
              type: "success",
            });
            this.formData.email = "";
            this.formData.password = "";
            this.formData.verifyCode = "";
            this.$props.handleClose?.();
            // this.$router.push("/signin")
          } else {
            ElMessage({
              message: response.data.msg,
              type: "error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleSendCode() {
      if (this.formData.loading || this.isValidEmail) {
        return;
      }
      if (!this.formData.email) {
        ElMessage({
          message: "please enter email",
          type: "warning",
        });
        return;
      }
      this.formData.loading = true;
      var config = {
        method: "get",
        url: `/api/ai-health/master-station/member-register/sendEmailVerificationCode?email=${this.formData.email}`,
      };
      axios(config)
        .then((response) => {
          this.formData.loading = false;
          if (response.data.code === 200) {
            ElMessage({
              message: "send success.",
              type: "success",
            });
            this.countdown = 60;
            const countdown = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown = this.countdown - 1;
              } else {
                clearInterval(countdown);
                this.countdown = 0;
              }
            }, 1000);
            this.formData.isSent = true;
          } else {
            ElMessage({
              message: response.data.msg,
              type: "error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.formData.loading = false;
        });
    },
  },
});
