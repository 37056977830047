
import axios from "axios";
import { useCodeClient } from "vue3-google-signin";
import { store } from "@/utils/store";

import { formatPassword } from "@/utils/password";
import { ElMessage, ElButton } from "element-plus";
import { defineComponent } from "vue";
import { googleSdkLoaded } from "vue3-google-login";
const { login } = useCodeClient({
  onSuccess: () => {
    console.log("sss");
  },
  onError: () => {
    console.log("err");
  },
  // other options
});
export default defineComponent({
  name: "App",
  components: {
    ElButton,
  },
  props: {
    visible: Boolean,
    handleClose: Function,
    handleOpenRegister: Function,
  },
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
      emailError: "",
      loading: false,
    };
  },
  computed: {
    isValidEmail() {
      const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
      if (this.formData.email && !this.formData.email.match(emailPattern)) {
        return "Invalid email address";
      } else {
        return "";
      }
    },
  },
  methods: {
    handleLogin() {
      this.login2();
    },
    login2() {
      googleSdkLoaded((google) => {
        google.accounts.oauth2
          .initCodeClient({
            client_id:
              "156941933896-iesrvnqlvhtah8jshdib2hshn5rchp04.apps.googleusercontent.com",
            scope: "email profile openid",
            callback: () => {
              ElMessage({
                message: "login success",
                type: "success",
              });
              store.handleSetIsLogin();
            },
          })
          .requestCode();
      });
    },
    callback() {
      console.log("test");
    },
    handleToOpenRegister() {
      this.$props.handleOpenRegister?.();
    },
    handleCloseLogin() {
      this.$props.handleClose?.();
    },
    handleSubmit() {
      if (this.loading || this.isValidEmail) {
        return;
      }
      if (!this.formData.email) {
        ElMessage({
          message: "please enter email",
          type: "warning",
        });
        return;
      }
      if (!this.formData.password) {
        ElMessage({
          message: "please enter password",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      var config = {
        method: "post",
        url: `/api/ai-health/master-station/member-login/forEmail?email=${
          this.formData.email
        }&password=${formatPassword(this.formData.password)}`,
      };

      axios(config)
        .then((response) => {
          if (response.data.code === 200) {
            this.handleCloseLogin?.();
            ElMessage({
              message: "login success",
              type: "success",
            });
            store.handleSetIsLogin();
            this.$router.push("/");
            // window.sessionStorage.setItem("login", "true");
          } else {
            ElMessage({
              message: response.data.msg,
              type: "error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
